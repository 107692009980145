
import { toRef, defineComponent, ref, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "edit-image-modal",
  components: {},
  props: ["targetImage"],
  setup(props, context) {
    const formImgRef = ref<null | HTMLFormElement>(null);
    const updatedImage = toRef(props, "targetImage");
    const editImageRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const store = useStore();

    const rules = ref({});

    const submit = function () {
      if (!formImgRef.value) {
        return;
      }

      formImgRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.update("api/v1/images", props.targetImage.id, {
            title: updatedImage.value.title,
          })
            .then(() => {
              hideModal(editImageRef.value);
              Swal.fire({
                text: "Image details saved!",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
              context.emit("imageUpdated");
            })
            .finally(() => {
              loading.value = false;
            });
        } else {
          Swal.fire({
            text: "Sorry, looks like there are some errors detected, please try again.",
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Ok, got it!",
            customClass: {
              confirmButton: "btn btn-primary",
            },
          });
          return false;
        }
      });
    };

    return {
      loading,
      rules,
      submit,
      editImageRef,
      formImgRef,
      updatedImage,
    };
  },
});
