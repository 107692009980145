
import { defineComponent, onMounted, ref, watch } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import NewImageModal from "../modals/NewImageModal.vue";
import EditImageModal from "../modals/EditImageModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import { hideModal } from "@/core/helpers/dom";

export default defineComponent({
  name: "mediaLibraryOverview",
  components: { NewImageModal, EditImageModal },
  setup() {
    const store = useStore();
    let baseUrl = process.env.VUE_APP_API_URL;

    const targetImage = ref(null);
    const loading = ref(false);

    const loadImages = function () {
      store.dispatch(Actions.REFRESH_IMAGES);
    };
    const deleteImage = function (img) {
      Swal.fire({
        title: "Are you sure you want to delete this image?",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "Cancel",
        dangerMode: true,
      }).then((result) => {
        if (result.isConfirmed) {
          loading.value = true;
          ApiService.delete("api/v1/images/" + img.id)
            .then((response) => {
              store.commit(Mutations.DELETE_ACCOUNT_IMAGE, img.id);
            })
            .finally(() => {
              loading.value = false;
            });
        }
      });
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Image Library", []);
      loadImages();
    });

    return {
      loadImages,
      deleteImage,
      targetImage,
      baseUrl,
      loading,
    };
  },
});
