
import { defineComponent, ref, reactive } from "vue";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "edit-location-modal",
  components: {},

  setup(props, context) {
    const file = ref<File | null>();
    const form = ref<HTMLFormElement>();

    const newImageRef = ref(null);
    const editLocationRef = ref<null | HTMLElement>(null);
    const loading = ref<number>(0);
    const store = useStore();

    function onFileChanged($event: Event) {
      const target = $event.target as HTMLInputElement;
      if (target && target.files) {
        file.value = target.files[0];
        saveImage();
      }
    }

    let targetData = reactive({
      uploadData: "",
    });
    const rules = ref({});

    async function saveImage() {
      if (file.value) {
        const formData = new FormData();
        formData.append("form[uploadedFile]", file.value);
        loading.value++;
        ApiService.upload(
          "api/v1/accounts/" + store.state.AuthModule.account.id + "/images",
          formData
        )
          .then((response) => {
            Swal.fire({
              text: "Image saved!",
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: "Ok",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            });
            context.emit("imageUpdated");
            hideModal(newImageRef.value);
          })
          .finally(() => {
            loading.value--;
          });
      }
    }

    return {
      targetData,
      onFileChanged,
      loading,
      rules,
      editLocationRef,
      newImageRef,
    };
  },
});
